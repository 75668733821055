body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F7F7F7;
}

.App{
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  justify-items: center;
  align-items: center;
  height: 90vh;
}

.heading {
  text-align: center;
  align-self: flex-start;
  margin-bottom: 30px;
}

#videos{
  position: relative;
  height: 85vh;
  width: 100vw;
  margin: auto;
  align-self: flex-start;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
  justify-items: center;
  align-items: center;
}

.vid{
  position: relative;
  background-color:white;
  height: 95%;
  width: 95%;
  border-width: 1px;
  border-color: #38373A;
  border-style: solid;
}

/* .controls{
  position: relative;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%); 
  display: grid;
  grid-template-columns: repeat(3, 33%);
  align-items: center;
  justify-items: center;
  z-index: 1;
  width: 500px;
  max-width: 60vw;
} */

.controls{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: black;
  color: white;
  text-align: center;


  display: grid;
  grid-template-columns: repeat(3, 20%);
  align-items: center;
  justify-items: center;
  z-index: 1;
}

.controls p{
  padding: 5px;
  cursor: pointer;
  /* background: #38373A; */
  color: #F7F7F7;
  border-width: 1px;
  border-color: #F7F7F7;
  border-style: solid;
}

.controls p.on{
  /* background: #F7F7F7; */
  color: #fff;
  border-width: 1px;  
  border-color: #38373A;  
  border-style: solid;
}

.join{
  position: relative;
  z-index: 1;
  width: 30vw;
  height: fit-content;
  height: -moz-max-content;
  top: 40vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  width: 500px;
  max-width: 75vw;
}

.join  input{
  padding: 15px;
  font-size: 1rem;
  border-width: 1px;
  border-color: #38373A;
  border-style: solid;
  width: 80%;
  display: block;
  margin:  50px auto;
}

.join  button{
  min-width: 200px;
  padding: 12px 0;
  text-align: center;
  background-color: #3699ff;
  border-radius: 10px;
  color: #F7F7F7;
  border-width: 1px;
  border-color: #F7F7F7;
  border-style: solid;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

.join p{
  line-height: 25px;
  text-align: center; 
}

.join a,.nav a,.footer a {
  text-decoration: none;
  color:#3699ff;
}

.container{
  /* height: 100vh; */
}

.nav {
  flex: 1;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 30px 50px 30px 50px;
}

.footer{
  position: absolute;
  bottom: 0; 
  display: flex;
  justify-content: center; 
  width: 100%;
  background-color: #ffffff;
  padding-left: 5px;
    padding-right: 5px;
}

.footer p {
  text-align: center;
}

.controls-wrapper{
  width: 100%;
  display: block;
  position: relative;
  text-align: center;
}
.control-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.control-container span{
  font-size: 10px;
  font-weight: 600;
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin-top: 5px;
 
}

.control-container svg{
  font-size: 14px;
}
.leave-button{
  background-color: red;
  font-size: 12px;
  font-weight: 600;
  padding:5px;
  padding-left: 10px !important;
  padding-right: 10px !important;
  border-radius: 5px;
}

.form-error button{
  min-width: 200px;
  padding: 12px 0;
  text-align: center;
  background-color: #3699ff;
  border-radius: 10px;
  color: #F7F7F7;
  border-width: 1px;
  border-color: #F7F7F7;
  border-style: solid;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

.btn-toolbar .btn{
  min-width: 100px;
  padding: 8px 0;
  text-align: center;
  background-color: #3699ff;
  border-radius: 10px;
  color: #F7F7F7;
  border-width: 1px;
  border-color: #F7F7F7;
  border-style: solid;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  display: block; 
}
.btn-toolbar .btn:hover{
 
  background-color: #F7F7F7; 
  color:  #3699ff;
  border-color:#3699ff ;
  border-width: 2px;
 
}